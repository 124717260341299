export default {
  methods: {
    goStep: function(step) {
      this.setQuery({ apply: step });
    },
  },
  computed: {
    apply: function() {
      return this.$route.query.apply;
    },
    isStep: function() {
      return this.apply == this.step;
    },
    buttonStyle: function() {
      let btStyle = {};

      if (this.$screen.width <= 590) {
        btStyle = this.style.step_mobile;
      }

      if (this.$screen.width > 590) {
        btStyle = this.style.step_tablet;
      }

      if (this.$screen.width > 768) {
        btStyle = this.style.step_pc;
      }

      return Object.assign(this.style.step, btStyle);
    },
  },
  data: function() {
    return {
      style: {
        step: {
          color: "#fff",
          "background-image": "var(--sec-gradient)",
          "border-radius": "50%",
          display: "flex",
          "justify-content": "center",
          "align-items": "center",
          "flex-direction": "column",
          "text-align": "center",
          transition: "all 0.3s ease-in-out",
          position: "relative",
          cursor: "pointer",
        },
        step_mobile: {
          width: "74px",
          height: "74px",
        },
        step_tablet: {
          width: "90px",
          height: "90px",
        },
        step_pc: {
          "font-size": "120%",
          width: "126px",
          height: "126px",
        },
        content: {
          position: "absolute",
          top: "4px",
          left: "4px",
          right: "4px",
          bottom: "4px",
          color: "var(--primary-color)",
          "text-align": "center",
          "background-color": "#fff",
          "border-radius": "50%",
          display: "flex",
          "justify-content": "center",
          "align-items": "center",
          "flex-direction": "column",
        },
      },
    };
  },
};
